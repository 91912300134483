import $ from 'jquery' //jquery@3.5.1
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'
import {BadRequestErorToMessage, CheckTextIsOfAllowedCharacters, ConverNumbersToEnFormat, GoogleAuthenticatorCodeLegth, IsExist, LocalStorageSetter, LogError, NormalizeFormInputValue, Numbers, SmsCodeLegth, UserPanelServerUrl} from '../../config'

const CheckCode = function (submitUrl, postData, setCodeIsCorrect, setErrorText, setLoadingButton, router) {
	setCodeIsCorrect(null)
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res.json()
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
				if(errorMessage != ""){
					setErrorText(errorMessage)
				}
			})
		}
		throw res
	})
	.then((resData) => {
		setLoadingButton(false)
		LocalStorageSetter("token", "Bearer " + resData.token)
		LocalStorageSetter("profileImageID", resData.profileImageID)
		if(IsExist(resData.userFullName)){
			LocalStorageSetter("userFullName", resData.userFullName)
		}
		setCodeIsCorrect(true)
	})
	.catch((error) => {
		setLoadingButton(false)
		LogError(error,router)
	})
}

const ReSendCode = function (submitUrl, postData, resetTimer, router) {
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		resetTimer()
	})
	.catch((error) => {
		LogError(error,router)
	})
}

export default function LoginCodeBox(props) {

	const router = useRouter()
	const[loadingButton, setLoadingButton] = useState(false)
	const[googleAuthenticatorCode, setGoogleAuthenticatorCode] = useState("")
	const[errorText, setErrorText] = useState(null)
	const[codeIsCorrect, setCodeIsCorrect] = useState(null)

	const codeType = props.codeType
	const resetTimer = props.resetTimer
	const setStepBox = props.setStepBox

	function getSmsCode(){
		let code = ""
		$('.account-step-code-inputs').each(function(i, obj) {
			$(this).blur()
			code += $(this).val()
		})
		return code
	}

	function accountStepCodeInputsOnKeyPress(e){
		if($(e).val().length > 0){ //control max length = 1
			return false
		}
	}

	function accountStepCodeInputsOnFocus(e){
		$(e).val('')
		setErrorText(null)
	}

	function sendCode() {
		if(loadingButton == false && getSmsCode().length == SmsCodeLegth){
			let code = getSmsCode()
			setLoadingButton(true)
			if(codeType == "register"){
				const postData = {
					phone: NormalizeFormInputValue($('#account-number-input').val()),
					code: NormalizeFormInputValue(code),
					googleCode: NormalizeFormInputValue($('#google-authenticator-code').val()),
				}
				CheckCode((UserPanelServerUrl + "/Authentication/CheckRegisterCode"), postData, setCodeIsCorrect, setErrorText, setLoadingButton, router)
			}else{
				const postData = {
					phone: NormalizeFormInputValue($('#account-number-input').val()),
					code: NormalizeFormInputValue(code),
					googleCode: NormalizeFormInputValue($('#google-authenticator-code').val()),
				}
				CheckCode((UserPanelServerUrl + "/Authentication/CheckLoginCode"), postData, setCodeIsCorrect, setErrorText, setLoadingButton, router)
			}
		}
	}

 	function resendCode(){
		const postData = {
			phone: NormalizeFormInputValue($('#account-number-input').val())
		}
		ReSendCode((UserPanelServerUrl + "/Authentication/ReSendCode"), postData, resetTimer, router)
	}

	function googleAuthenticatorCodeInputHandler(value){
		value = ConverNumbersToEnFormat(value)
		if(CheckTextIsOfAllowedCharacters(value,Numbers)){
			setGoogleAuthenticatorCode(value)
			setErrorText(null)
			if(value.length == GoogleAuthenticatorCodeLegth){
				sendCode()
			}
		}
	}

	function accountStepCodeInputsOnInput(e){
		if($(e).val().length > 0){
			let index = $(e).data("index")
			$('.account-step-code-inputs').each(function(i, obj) {
				if(index + 1 == $(this).data("index")){
					$(this).focus()
				}
				if(index == $('.account-step-code-inputs').length && $('.account-step-code-inputs').length == i + 1){
					sendCode()
				}
			})
		}
	}

	function editNumber(){
		setStepBox("account-step-number")
	}

	useEffect(()=>{
		if(IsExist(codeIsCorrect) && codeIsCorrect == true){
			if(codeType == "register"){
				setStepBox("account-step-reset-password")
			}else if(codeType == "forget-password"){
				setStepBox("account-step-reset-password")
			}else{
				router.push("/panel/account")
			}
		}
	}, [codeIsCorrect, router])

	return (
		<div className="px-4 py-4 account-step-code-box">
			<div className="d-flex flex-column justify-content-center align-items-center mx-3 my-3">
				<div className="text-center">کد تایید برای شماره<span id="send-code-phone-number" className="fw-bold px-1"></span>ارسال شده است</div>
				<div className="login-boxs-item-seprator"></div>
				<div className="text-center">آیا شماره موبایل اشتباه است؟<a href={"#"} onClick={e=>editNumber()} id="edit-number" className="d-inline-block px-1">ویرایش شماره</a></div>
				<div id="google-authenticator-layer" className="d-none w-100">
					<div className="login-boxs-item-seprator"></div>
					<div className="text-center fw-bold mb-2">کد تایید Google Authenticator را وارد کنید</div>
					<div className="w-100 d-flex align-items-center justify-content-center google-authenticator-code-box">
						<input value={googleAuthenticatorCode} onChange={e=>googleAuthenticatorCodeInputHandler(e.target.value)} maxLength={GoogleAuthenticatorCodeLegth} name="googleAuthenticatorCode" id="google-authenticator-code" type="text" placeholder="- - - - - -" className={(errorText == null ? "" : "red-border-inputs") + " hide-number-inputs-arrows w-100 h-100 text-center"} style={{direction:"ltr"}}/>
					</div>
				</div>
				<div className="login-boxs-item-seprator"></div>
				<div className="text-center fw-bold mb-2">کد تایید اس ام اس را وارد کنید</div>
				<div className="w-100 d-flex align-items-center account-step-code-inputs-box">
					<div className="mx-1 account-code-inputs-box d-flex d-flex align-items-center justify-content-center">
						<input onFocus={e=>accountStepCodeInputsOnFocus(e.target)} onKeyPress={e=>accountStepCodeInputsOnKeyPress(e.target)} onInput={e=>accountStepCodeInputsOnInput(e.target)} type="number" placeholder="-" data-index="1" className={(errorText == null ? "" : "red-border-inputs") + " account-step-code-inputs blue-border-inputs hide-number-inputs-arrows w-100 h-100 text-center"}/>
					</div>
					<div className="mx-1 account-code-inputs-box d-flex d-flex align-items-center justify-content-center">
						<input onFocus={e=>accountStepCodeInputsOnFocus(e.target)} onKeyPress={e=>accountStepCodeInputsOnKeyPress(e.target)} onInput={e=>accountStepCodeInputsOnInput(e.target)} type="number" placeholder="-" data-index="2" className={(errorText == null ? "" : "red-border-inputs") + " account-step-code-inputs blue-border-inputs hide-number-inputs-arrows w-100 h-100 text-center"}/>
					</div>
					<div className="mx-1 account-code-inputs-box d-flex d-flex align-items-center justify-content-center">
						<input onFocus={e=>accountStepCodeInputsOnFocus(e.target)} onKeyPress={e=>accountStepCodeInputsOnKeyPress(e.target)} onInput={e=>accountStepCodeInputsOnInput(e.target)} type="number" placeholder="-" data-index="3" className={(errorText == null ? "" : "red-border-inputs") + " account-step-code-inputs blue-border-inputs hide-number-inputs-arrows w-100 h-100 text-center"}/>
					</div>
					<div className="mx-1 account-code-inputs-box d-flex d-flex align-items-center justify-content-center">
						<input onFocus={e=>accountStepCodeInputsOnFocus(e.target)} onKeyPress={e=>accountStepCodeInputsOnKeyPress(e.target)} onInput={e=>accountStepCodeInputsOnInput(e.target)} type="number" placeholder="-" data-index="4" className={(errorText == null ? "" : "red-border-inputs") + " account-step-code-inputs blue-border-inputs hide-number-inputs-arrows w-100 h-100 text-center"}/>
					</div>
					<div className="mx-1 account-code-inputs-box d-flex d-flex align-items-center justify-content-center">
						<input onFocus={e=>accountStepCodeInputsOnFocus(e.target)} onKeyPress={e=>accountStepCodeInputsOnKeyPress(e.target)} onInput={e=>accountStepCodeInputsOnInput(e.target)} type="number" placeholder="-" data-index="5" className={(errorText == null ? "" : "red-border-inputs") + " account-step-code-inputs blue-border-inputs hide-number-inputs-arrows w-100 h-100 text-center"}/>
					</div>
				</div>
				{
					errorText == null ? "" :
					<>
						<div className="login-boxs-item-seprator"></div>
						<span className="small-error-text pt-1">{errorText}</span>
					</>
				}
				{
					loadingButton == false ? 
					<div className="login-boxs-item-seprator"></div>
					:
					<div className="w-100 d-flex justify-content-center align-items-center pt-3 pb-3">
						<div className="small-primary-lds-dual-ring"></div>
					</div>
				}
				<div id="resend-code-btn" className="d-none text-center">آیا کد تایید را دریافت نکرده‌اید؟<a href={"#"} onClick={e=>resendCode()} id="resend-code-text" className="d-inline-block px-1">ارسال دوباره کد</a></div>
				<div id="resend-code-timing" className="text-center">ارسال دوباره کد تا<span id="resend-code-timer" className="d-inline-block px-2 fw-bold">--:--</span>دیگر</div>
			</div>
		</div>
	)
}
