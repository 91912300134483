import $ from 'jquery' //jquery@3.5.1
import Image from "next/image"
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'
import {BadRequestErorToMessage, CompanyName, ImageLoader, IsExist, LogError, MinimumPasswordLegth, NormalizeFormInputValue, StaticImagesVersion, UserPanelServerUrl} from "../../config"

const CheckPassword = function (submitUrl, postData, setPasswordIsCorrect, setErrorText, setLoadingButton, router) {
	setPasswordIsCorrect(null)
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
				if(errorMessage != ""){
					setErrorText(errorMessage)
				}
			})
		}
		throw res
	})
	.then((resData) => {
		setLoadingButton(false)
		setPasswordIsCorrect(true)
	})
	.catch((error) => {
		setLoadingButton(false)
		LogError(error,router)
	})
}

const ForgetPassword = function (submitUrl, postData, setForgetPasswordCode, router) {
	setForgetPasswordCode(null)
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
			})
		}
		throw res
	})
	.then((resData) => {
		setForgetPasswordCode(true)
	})
	.catch((error) => {
		LogError(error,router)
	})
}

export default function PasswordBox(props) {

	const router = useRouter()
	const[errorText, setErrorText] = useState(null)
	const[loadingButton, setLoadingButton] = useState(false)
	const[passwordIsCorrect, setPasswordIsCorrect] = useState(null)
	const[forgetPasswordCode, setForgetPasswordCode] = useState(null)

	const setStepBox = props.setStepBox
	const setCodeType = props.setCodeType
	const resetTimer = props.resetTimer

	function forgetPassword(){
		const postData = {
			phone: NormalizeFormInputValue($('#account-number-input').val())
		}
		ForgetPassword((UserPanelServerUrl + "/Authentication/ForgetPassword") , postData, setForgetPasswordCode, router)
	}

	function accountPasswordInput(e){
		if($(e).val().length >= MinimumPasswordLegth){
			$("#account-password-continue-btn").removeClass("account-password-action-btn-disable")
		}else{
			$("#account-password-continue-btn").addClass("account-password-action-btn-disable")
		}
		setErrorText(null)
	}

	const accountPasswordContinueBtn = (event) => {
		event.preventDefault()
		if(loadingButton == false){
			let password = $('#account-password-input').val()
			if(password.length >= MinimumPasswordLegth){
				setLoadingButton(true)
				const postData = {
					phone: NormalizeFormInputValue($('#account-number-input').val()),
					password: NormalizeFormInputValue(password),
				}
				CheckPassword((UserPanelServerUrl + "/Authentication/CheckPassword"), postData, setPasswordIsCorrect, setErrorText, setLoadingButton, router)
			}
		}
	}

	useEffect(()=>{
		if(IsExist(passwordIsCorrect)){
			setStepBox("account-step-code")
			setCodeType("login")
			resetTimer()
			$('.account-step-code-inputs').each(function(i, obj) {
				$(obj).val('')
			})
		}
	}, [passwordIsCorrect])

	useEffect(()=>{
		if(IsExist(forgetPasswordCode)){
			setStepBox("account-step-code")
			setCodeType("forget-password")
			resetTimer()
			$('.account-step-code-inputs').each(function(i, obj) {
				$(obj).val('')
			})
		}
	}, [forgetPasswordCode])

	return (
		<div className="px-4 py-4 header-right-layer">
			<form onSubmit={accountPasswordContinueBtn} className="d-flex flex-column justify-content-center align-items-center mx-3 my-3">
				<div className="account-company-icon position-relative mb-2">
					<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/logo.png?v=" + StaticImagesVersion} alt={CompanyName}/>
				</div>
				<div className="text-center fw-bold">برای ادامه، لطفا رمز عبور حساب کاربری خود را وارد کنید</div>
				<div className="login-boxs-item-seprator"></div>
				<div className="w-100 d-flex align-items-center justify-content-center account-password-box">
					<input onInput={e=>accountPasswordInput(e.target)} id="account-password-input" type="password" placeholder="* * * * * * * *" className={(errorText == null ? "" : "red-border-inputs") + " w-100 h-100 text-center"} style={{direction:"ltr"}}/>
				</div>
				{
					errorText == null ? "" : <span className="small-error-text pt-1">{errorText}</span>
				}
				<div className="login-boxs-item-seprator"></div>
				<button type="submit" id="account-password-continue-btn" className="account-password-action-btn account-password-action-btn-disable w-100 text-center px-4 py-2 c-transition">
					{
						loadingButton == false ? "تایید" :
						<div className="w-100 d-flex justify-content-center align-items-center">
							<div className="small-lds-dual-ring"></div>
						</div>
					}
				</button>
				<div className="login-boxs-item-seprator"></div>
				<a href={"#"} onClick={e=>forgetPassword()} id="forget-password" className="d-inline-block px-1">رمز عبور خود را فراموش کرده اید؟</a>
			</form>
		</div>
	)
}
