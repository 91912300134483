import $ from 'jquery' //jquery@3.5.1
import Image from "next/image"
import {useRouter} from 'next/router'
import {useState} from 'react'
import {BadRequestErorToMessage, CompanyName, ImageLoader, LocalStorageGetter, LogError, MinimumPasswordLegth, NormalizeFormInputValue, StaticImagesVersion, UserPanelServerUrl} from "../../config"

const ResetPassword = function (submitUrl, postData, router, setErrorText, setLoadingButton) {
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json',
			'Authorization': LocalStorageGetter("token") !== null ? LocalStorageGetter("token") : ""
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
				if(errorMessage != ""){
					setErrorText(errorMessage)
				}
			})
		}
		throw res
	})
	.then((resData) => {
		setLoadingButton(false)
		router.push("/panel/account")
	})
	.catch((error) => {
		setLoadingButton(false)
		LogError(error,router)
	})
}

export default function ResetPasswordBox(props) {

	const router = useRouter()
	const[loadingButton, setLoadingButton] = useState(false)
	const[errorText, setErrorText] = useState(null)

	function accountResetPasswordInput(){
		let newPassword = $('#new-password-input').val()
		let renewPassword = $('#renew-password-input').val()
		if(newPassword.length >= MinimumPasswordLegth && renewPassword.length >= MinimumPasswordLegth){
			$("#reset-password-continue-btn").removeClass("account-password-action-btn-disable")
		}else{
			$("#reset-password-continue-btn").addClass("account-password-action-btn-disable")
		}
		setErrorText(null)
	}

	const resetPasswordContinueBtn = (event) => {
		event.preventDefault()
		if(loadingButton == false){
			let newPassword = $('#new-password-input').val()
			let renewPassword = $('#renew-password-input').val()
			if(newPassword.length >= MinimumPasswordLegth && renewPassword.length >= MinimumPasswordLegth && newPassword == renewPassword){
				setLoadingButton(true)
				const postData = {
					password: NormalizeFormInputValue(newPassword),
				}
				ResetPassword((UserPanelServerUrl + "/Authentication/ResetPassword"), postData, router, setErrorText, setLoadingButton)
			}else if(newPassword != renewPassword){
				setErrorText("رمز عبور با تکرار رمز عبور مطابقت ندارد")
			}
		}
	}

	return(
		<div className="px-4 py-4 header-right-layer">
			<form onSubmit={resetPasswordContinueBtn} className="d-flex flex-column justify-content-center align-items-center mx-3 my-3">
				<div className="account-company-icon position-relative mb-2">
					<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/logo.png?v=" + StaticImagesVersion} alt={CompanyName}/>
				</div>
				<div className="text-center fw-bold">برای ادامه، لطفا رمز عبور و تکرار رمز عبور را وارد کنید</div>
				<div className="login-boxs-item-seprator"></div>
				<div className="w-100 d-flex align-items-center justify-content-center account-password-box">
					<input onInput={e=>accountResetPasswordInput()} id="new-password-input" type="password" placeholder="رمز عبور" className={(errorText == null ? "" : "red-border-inputs") + " w-100 h-100 text-center"} style={{direction:"ltr"}}/>
				</div>
				<div className="login-boxs-item-seprator"></div>
				<div className="w-100 d-flex align-items-center justify-content-center account-password-box">
					<input onInput={e=>accountResetPasswordInput()} id="renew-password-input" type="password" placeholder="تکرار رمز عبور" className={(errorText == null ? "" : "red-border-inputs") + " w-100 h-100 text-center"} style={{direction:"ltr"}}/>
				</div>
				{
					errorText == null ? "" : <span className="small-error-text pt-1">{errorText}</span>
				}
				<div className="login-boxs-item-seprator"></div>
				<ul className="mb-0" style={{fontSize:"12px"}}>
					<li>طول رمز عبور نباید کمتر از 8 حرف باشد</li>
					<li>حداقل یک شماره ضروری است</li>
					<li>حداقل یکی از علائم (!@#$-,+.=?_%^&*) ضروری است</li>
					<li>استفاده از حروف غیر انگلیسی صحیح نیست</li>
					<li>حداقل یک حرف بزرگ انگلیسی ضروری است</li>
					<li>حداقل یک حرف کوچک انگلیسی ضروری است</li>
				</ul>
				<div className="login-boxs-item-seprator"></div>
				<button type="submit" id="reset-password-continue-btn" className="account-password-action-btn account-password-action-btn-disable w-100 text-center px-4 py-2 c-transition">
					{
						loadingButton == false ? "تایید" :
						<div className="w-100 d-flex justify-content-center align-items-center">
							<div className="small-lds-dual-ring"></div>
						</div>
					}
				</button>
			</form>
		</div>
	)
}
