import $ from 'jquery' //jquery@3.5.1
import Image from "next/image"
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'
import {BadRequestErorToMessage, CheckTextIsOfAllowedCharacters, CompanyName, ConverNumbersToEnFormat, ImageLoader, IsExist, LogError, NormalizeFormInputValue, Numbers, StaticImagesVersion, UserPanelServerUrl} from "../../config"
import Link from 'next/link'

const CheckPhone = function (submitUrl, postData, setIsNewUser, setGoogleAuthenticatorIsEnable, setErrorText, setLoadingButton, router) {
	setIsNewUser(null)
	setGoogleAuthenticatorIsEnable(null)
	const JSONdata = JSON.stringify(postData)
	const options = {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSONdata,
	}
	fetch(submitUrl, options)
	.then(async (res) => {
		if(res.status == 200){
			return res.json()
		}else if(res.status == 400){
			res.json().then(function(result) {
				const { code } = result
				const errorMessage = BadRequestErorToMessage(code,null,result,router)
				if(errorMessage != ""){
					setErrorText(errorMessage)
				}
			})
		}
		throw res
	})
	.then((resData) => {
		setLoadingButton(false)
		setIsNewUser(resData.isNewUser)
		setGoogleAuthenticatorIsEnable(resData.googleAuthenticatorIsEnable)
	})
	.catch((error) => {
		setLoadingButton(false)
		LogError(error,router)
	})
}

export default function PhoneNumberBox(props) {

	const router = useRouter()
	const[phoneNumber, setPhoneNumber] = useState("")
	const[errorText, setErrorText] = useState(null)
	const[loadingButton, setLoadingButton] = useState(false)
	const[isNewUser, setIsNewUser] = useState(null)
	const[googleAuthenticatorIsEnable, setGoogleAuthenticatorIsEnable] = useState(null)

	const setStepBox = props.setStepBox
	const setCodeType = props.setCodeType
	const resetTimer = props.resetTimer

	function accountNumberInputHandler(value){
		value = ConverNumbersToEnFormat(value)
		if(CheckTextIsOfAllowedCharacters(value,Numbers)){
			setPhoneNumber(value)
			setErrorText(null)
			if(value.length == 11){
				$("#account-continue-btn").removeClass("account-action-btn-disable")
			}else{
				$("#account-continue-btn").addClass("account-action-btn-disable")
			}
		}
	}

	function validatePhone (phone) {
		return (/09([0-9]{9})/.test(phone))
	}

	const accountContinueBtn = (event) => {
		event.preventDefault()
		if(loadingButton == false){
			let phone = $('#account-number-input').val()
			if(phone.length == 11){
				if(validatePhone(phone)){
					const postData = {
						phone: NormalizeFormInputValue(phone),
					}
					setLoadingButton(true)
					CheckPhone((UserPanelServerUrl + "/Authentication/CheckPhone"), postData, setIsNewUser, setGoogleAuthenticatorIsEnable, setErrorText, setLoadingButton, router)
				}else{
					setErrorText("شماره موبایل وارد شده صحیح نمی‌باشد")
				}
			}
		}
	}

	useEffect(()=>{
		if(IsExist(isNewUser) && IsExist(googleAuthenticatorIsEnable)){
			let phone = $('#account-number-input').val()
			$("#send-code-phone-number").text(phone)
			if(isNewUser){
				setStepBox("account-step-code")
				setCodeType("register")
				resetTimer()
				$('.account-step-code-inputs').each(function(i, obj) {
					$(obj).val('')
				})
			}else{
				setStepBox("account-step-password")
				setCodeType("login")
				$("#account-password-input").val('')
				$("#account-password-continue-btn").addClass("account-password-action-btn-disable")
			}

			if(googleAuthenticatorIsEnable){
				$("#google-authenticator-layer").removeClass("d-none")
			}else{
				$("#google-authenticator-layer").addClass("d-none")
			}
		}
	}, [isNewUser, googleAuthenticatorIsEnable])

	return (
		<div className="px-4 py-4 header-right-layer">
			<form onSubmit={accountContinueBtn} className="d-flex flex-column justify-content-center align-items-center mx-3 my-3">
				<div className="account-company-icon position-relative mb-2">
					<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/logo.png?v=" + StaticImagesVersion} alt={CompanyName}/>
				</div>
				<div className="text-center fw-bold">برای ورود یا ثبت نام، لطفا شماره موبایل خود را وارد کنید</div>
				<div className="login-boxs-item-seprator"></div>
				<div className="w-100 d-flex align-items-center justify-content-center account-number-box">
					<input value={phoneNumber} onChange={e=>accountNumberInputHandler(e.target.value)} maxLength={11} name="phoneNumber" id="account-number-input" type="text" placeholder="09xx xxx xxxx" className={(errorText == null ? "" : "red-border-inputs") + " hide-number-inputs-arrows w-100 h-100 text-center"} style={{direction:"ltr"}}/>
				</div>
				{
					errorText == null ? "" : <span className="small-error-text pt-1">{errorText}</span>
				}
				<div className="py-1"></div>
				<span style={{fontSize:"12px"}}>شماره موبایل حتما باید به نام خودتان باشد.</span>
				<div className="login-boxs-item-seprator"></div>
				<button type="submit" id="account-continue-btn" className="account-action-btn account-action-btn-disable w-100 text-center px-4 py-2 c-transition">
					{
						loadingButton == false ? "تایید" :
						<div className="w-100 d-flex justify-content-center align-items-center">
							<div className="small-lds-dual-ring"></div>
						</div>
					}
				</button>
				<div className="py-1"></div>
				<span style={{fontSize:"12px"}}>با تایید شماره موبایل <Link href={"/terms"}>قوانین و مقررات</Link> را می‌پذیرم.</span>
			</form>
		</div>
	)
}
