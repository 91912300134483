import Head from "next/head"
import Image from "next/image"
import Link from "next/link"
import {useRouter} from "next/router"
import {useEffect, useState} from "react"
import {CompanyName, GetUserFullName, ImageLoader, IsExist, IsLogin, LocalStorageSetter, ScriptsVersion, StaticImagesVersion, ThemeIsDark} from '../config'
import {Registerlogin} from "./login"

export default function Header(props) {

	const router = useRouter()
	const[darkLightState, setDarkLightState] = useState(ThemeIsDark())
	const[isDark, setIsDark] = useState(ThemeIsDark())
	const[panelButtonText, setpanelButtonText] = useState(null)

	const showForumButton = false
	const showApplicationButton = false

	function toggledarklightlayer(){
		LocalStorageSetter('theme-state', isDark ? "light" : "dark")
		setIsDark(!isDark)
		setColors(!isDark)
	}

	useEffect(()=>{
		setDarkLightState(isDark ? "dark" : "light")
	}, [isDark])

	useEffect(()=>{
		setIsDark(ThemeIsDark())
		if(IsLogin()){
			if(IsExist(GetUserFullName())){
				setpanelButtonText(GetUserFullName())
			}else{
				setpanelButtonText("پنل کاربری")
			}
		}else{
			setpanelButtonText("ثبت نام | ورود")
		}
	}, [])

	return (
		<>
			<Head>
				<script async src={"/js/theme.js?v=" + ScriptsVersion}></script>
			</Head>
			<header className="main-header">
				<nav className="w-100 h-100">
					<div className="w-100 h-100 d-flex flex-row" id="header-layer">
						<div className="header-right-box d-flex align-items-center h-100 px-3">
							<Link href={"/"}>
								<a className="reset-a-styles">
									<div className="d-flex justify-content-center align-items-center">
										<div id="header-company-icon">
											<Image loader={ImageLoader} layout="fill" objectFit="contain" loading="lazy" src={"/images/logo.png?v=" + StaticImagesVersion} alt={CompanyName}/>
										</div>
										<div id="header-company-name">صرافی {CompanyName}</div>
									</div>
								</a>
							</Link>
							<div className="d-flex header-right-menu-space-box"></div>
							<Link href={"/currencies"}><a className="design-simple-btn header-main-links" data-index="1">قیمت لحظه‌ای</a></Link>
							<Link href={""}><a className="design-simple-btn header-main-links" data-index="2">آموزش</a></Link>
							{ showForumButton ? <Link href={""}><a className="design-simple-btn header-main-links" data-index="3">تالار گفتگو</a></Link> : <></> }
							<Link href={"/fees"}><a className="design-simple-btn header-main-links" data-index="4">کارمزدها</a></Link>
							<div className="d-flex justify-content-center position-relative">
								<a id="closeable-menu-button" className="design-simple-btn d-flex flex-row">
									<span id="header-closeable-menu-name"></span>
									<div className="d-block p-0 pt-1 header-closeable-menu-icon">
										<svg className="d-block w-100 h-100" viewBox="0 0 16 16"><path fill="CurrentColor" fillRule="nonzero" transform="rotate(0)" d="M8 10.7c-.18 0-.36-.07-.496-.207L3.205 6.194a.7.7 0 01.988-.99L8 9.008l3.802-3.801A.703.703 0 0113 5.7a.698.698 0 01-.205.494l-4.302 4.302A.711.711 0 018 10.7z"></path></svg>
									</div>
								</a>
								<div id="header-closeable-menu-box" className="flex-column justify-content-center align-items-center pt-2">
									<Link href={"/currencies"}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 header-sub-links" data-index="1"><span className="sub-menu-point"></span><span>قیمت لحظه‌ای</span></a></Link>
									<Link href={""}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 header-sub-links" data-index="2"><span className="sub-menu-point"></span><span>آموزش</span></a></Link>
									{ showForumButton ? <Link href={""}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 header-sub-links" data-index="3"><span className="sub-menu-point"></span><span>تالار گفتگو</span></a></Link> : <></> }
									<Link href={"/fees"}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 header-sub-links" data-index="4"><span className="sub-menu-point"></span><span>کارمزدها</span></a></Link>
									<Link href={"/user-levels"}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 d-flex"><span className="sub-menu-point"></span><span>سطوح کاربری</span></a></Link>
									<Link href={"/faqs"}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 d-flex"><span className="sub-menu-point"></span><span>سوالات متداول</span></a></Link>
									<Link href={"/terms"}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 d-flex"><span className="sub-menu-point"></span><span>قوانین و مقررات</span></a></Link>
									<Link href={"/about-us"}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 d-flex"><span className="sub-menu-point"></span><span>درباره ما</span></a></Link>
									<Link href={"/contact-us"}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 d-flex"><span className="sub-menu-point"></span><span>ارتباط با ما</span></a></Link>
									{ showApplicationButton ? <Link href={""}><a className="align-items-center design-simple-btn w-100 mb-2 px-4 header-sub-links" data-index="download"><span className="sub-menu-point"></span><span>دانلود اپلیکیشن</span></a></Link> : <></> }
								</div>
							</div>
						</div>
						<div className="header-left-box d-flex align-items-center h-100 px-3 justify-content-end">
							{ showApplicationButton ? <Link href={""}><a className="design-simple-btn header-main-links" data-index="download">دانلود اپلیکیشن</a></Link> : <></> }
							<div onClick={e=>Registerlogin(router)} id="account-button" className="register-login design-primary-btn d-flex justify-content-center align-items-center c-transition px-3 py-1 py-sm-2 py-md-2 text-nowrap mx-3">
								<span style={{overflow:"hidden", textOverflow:"ellipsis"}}>{panelButtonText}</span>
							</div>
							<div onClick={e=>toggledarklightlayer()} id="toggle-darklight-layer">
								{
									darkLightState == "dark" ? 
									<div id="light-state" className="d-block px-0 h-100 header-darklight-btn">
										<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M256 159.1c-53.02 0-95.1 42.98-95.1 95.1S202.1 351.1 256 351.1s95.1-42.98 95.1-95.1S309 159.1 256 159.1zM509.3 347L446.1 255.1l63.15-91.01c6.332-9.125 1.104-21.74-9.826-23.72l-109-19.7l-19.7-109c-1.975-10.93-14.59-16.16-23.72-9.824L256 65.89L164.1 2.736c-9.125-6.332-21.74-1.107-23.72 9.824L121.6 121.6L12.56 141.3C1.633 143.2-3.596 155.9 2.736 164.1L65.89 256l-63.15 91.01c-6.332 9.125-1.105 21.74 9.824 23.72l109 19.7l19.7 109c1.975 10.93 14.59 16.16 23.72 9.824L256 446.1l91.01 63.15c9.127 6.334 21.75 1.107 23.72-9.822l19.7-109l109-19.7C510.4 368.8 515.6 356.1 509.3 347zM256 383.1c-70.69 0-127.1-57.31-127.1-127.1c0-70.69 57.31-127.1 127.1-127.1s127.1 57.3 127.1 127.1C383.1 326.7 326.7 383.1 256 383.1z"/></svg>
									</div>
									:
									<div id="dark-state" className="d-block px-0 h-100 header-darklight-btn">
										<svg className="d-block w-100 h-100" viewBox="0 0 512 512"><path fill="CurrentColor" d="M32 256c0-123.8 100.3-224 223.8-224c11.36 0 29.7 1.668 40.9 3.746c9.616 1.777 11.75 14.63 3.279 19.44C245 86.5 211.2 144.6 211.2 207.8c0 109.7 99.71 193 208.3 172.3c9.561-1.805 16.28 9.324 10.11 16.95C387.9 448.6 324.8 480 255.8 480C132.1 480 32 379.6 32 256z"/></svg>
									</div>
								}
							</div>
						</div>
					</div>
				</nav>
			</header>
		</>
	)
}
