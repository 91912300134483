import $ from 'jquery' //jquery@3.5.1
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'
import {IsExist, IsLogin} from '../../config'
import LoginCodeBox from './login-code-box'
import PasswordBox from './password-box'
import PhoneNumberBox from './phone-number-box'
import ResetPasswordBox from './reset-password-box'

export const Registerlogin = function (router) {
	if(IsLogin()){
		router.push("/panel/account")	
	}else{
		if($("#account-layer").data("state") == "open"){
			$("#account-layer").data("state", "close")
			$("#account-layer").addClass("d-none")
		}else{
			$("#account-layer").data("state", "open")
			$("#account-layer").removeClass("d-none")
		}
	}
}

export default function Login(props) {

	const router = useRouter()

	const[stepBox, setStepBox] = useState("account-step-number") // account-step-number , account-step-reset-password , account-step-password , account-step-code
	const[codeType, setCodeType] = useState("login") // login , register , forget-password
	
	const[check, setCheck] = useState(0)

	const[minute, setMinute] = useState(null)
	const[second, setSecond] = useState(null)

	const resetTimer = function () {
		setMinute(2)
		setSecond(0)
		setCheck(check + 1)
		$("#resend-code-timing").removeClass("d-none")
		$("#resend-code-btn").addClass("d-none")
	}

	useEffect(() => {
		if(IsExist(minute) && IsExist(second)){
			const id = setInterval(() => {
				if(minute > 0 || second > 0){
					let minuteText = minute.toString().length == 1 ? "0" + minute.toString() : minute
					let secondText = second.toString().length == 1 ? "0" + second.toString() : second
					$("#resend-code-timer").text(minuteText + ":" + secondText)
					if(second == 0){
						setMinute(minute - 1)
						setSecond(59)
					}else{
						setSecond(second - 1)
					}
					setCheck(check + 1)
				}else{
					$("#resend-code-timing").addClass("d-none")
					$("#resend-code-btn").removeClass("d-none")
				}
			}, 1000)
			return () => clearInterval(id)
		}
	}, [check, minute, second])

	return (
		<div id="account-layer" data-state="close" className="d-none flex-column justify-content-center align-items-center">
			<div onClick={e=>Registerlogin(router)} className="register-login account-close-box">
				<svg className="d-block w-100 h-100" viewBox="0 0 320 512"><path fill="white" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
			</div>
			<div className="py-2"></div>
			<div id="account-step-number" className={(stepBox == "account-step-number" ? "" : "d-none") + " flex-column justify-content-center align-items-center px-3"}>
				<PhoneNumberBox setCodeType={setCodeType} setStepBox={setStepBox} resetTimer={resetTimer}/>
			</div>
			<div id="account-step-password" className={(stepBox == "account-step-password" ? "" : "d-none") + " flex-column justify-content-center align-items-center px-3"}>
				<PasswordBox setCodeType={setCodeType} setStepBox={setStepBox} resetTimer={resetTimer}/>
			</div>
			<div id="account-step-code" className={(stepBox == "account-step-code" ? "" : "d-none") + " flex-column justify-content-center align-items-center px-3"}>
				<LoginCodeBox setCodeType={setCodeType} setStepBox={setStepBox} resetTimer={resetTimer} codeType={codeType}/>
			</div>
			<div id="account-step-reset-password" className={(stepBox == "account-step-reset-password" ? "" : "d-none") + " flex-column justify-content-center align-items-center px-3"} style={{maxHeight:"80vh", overflow:"auto"}}>
				<ResetPasswordBox/>
			</div>
		</div>
	)
}
